import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './side.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Popover, Switch } from 'antd';
import { Box } from '@mui/material';
import Intain from '../../Images/Intain_Brain.svg';
import IntainLogo from '../../Images/IntainLogo.png';
import IntainLogoDark from '../../Images/IntainLogo_dark.png';
import Queue from '../../Images/Queue_icon.svg';
import QueueDark from '../../Images/Queue_dark.svg';
import Processor from '../../Images/Processor.svg';
import ProcessorDark from '../../Images/Processor_dark.svg';
import Document from '../../Images/Document.svg';
import DocumentDark from '../../Images/Document_dark.svg';
import Field from '../../Images/Field.svg';
import FieldDark from '../../Images/Fields_dark.svg';
import Upload from '../../Images/Upload.svg';
import UploadDark from '../../Images/upload_dark.svg';
import Info from '../../Images/info.svg';
import InfoDark from '../../Images/info_dark.svg';
import Devinfo from '../../Images/Devinfo.svg';
import Devhub from '../../Images/Devhub.svg';
import profile from '../../Images/profile_img.svg'
import Moon from '../../Images/Moon.svg';
import Sun from '../../Images/Sun.svg';
import Dashboard from '../../Images/Dashboard.svg';
import DashboardDark from '../../Images/Dashboard_dark.svg';
import Analytics from '../../Images/Analytics_Dash_Icon.svg';
import { handleEditing } from '../../Store/Common/CommonAction';
import { setCurrentPageForInfinityScroll } from '../../Store/User/userAction';

const SideNavbar = ({processorModule}) => {
    const location = useLocation();
    const { pathname } = location;
    const history = useNavigate();
    const dispatch = useDispatch();
    const splitLocation = pathname.split('/');
    const [info, setInfo] = useState(false);
    const [showUserInfoModal, setShowUserInfoModal] = useState(false);
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const { isEditing } = useSelector(state => state.common);
    const [isDarkMode, setIsDarkMode] = useState(
      sessionStorage.getItem("dark-theme") === "dark"
    );
    const user_name = sessionStorage.getItem('name');



    const toggleTheme = () => {
      const newTheme = !isDarkMode;
      setIsDarkMode(newTheme);
      const theme = newTheme ? "dark" : "light";
      document.documentElement.setAttribute("dark-theme", theme); 
      sessionStorage.setItem("theme", theme); 
    };
    
    useEffect(() => {
      const currentTheme = sessionStorage.getItem("theme") || "light"; 
      document.documentElement.setAttribute("dark-theme", currentTheme);
      setIsDarkMode(currentTheme === "dark");
    }, []);

    console.log(splitLocation,'into routerrrr')

    const openInfoModal = () => {
      setInfo(true);
    };
    const closeInfoModal = () => {
      setInfo(false);
    };
    const handleShowUserInfoModal = () => {
      setShowUserInfoModal(true);
      console.log('into show user info modal')
    };
    const handleCloseUserInfoModal = () => {
      setShowUserInfoModal(false);
      console.log('into close user info modal')
    };
    
  const logout = () => {
    sessionStorage.clear();
    window.location.href = '/';
  };
  useEffect(() => {
    const blurContainer =
      document.querySelector("#mainContent");
    if (showUserInfoModal) {
      blurContainer.classList.add("blur-background");
    } else {
      blurContainer.classList.remove("blur-background");
    }
    return () => blurContainer.classList.remove("blur-background");
  }, [showUserInfoModal]);

    const content = (
      <>
        <div
          onClick={() => {
            //   setReportModalShow(true);
            closeInfoModal(false);
          }}
          className="devinfo"
        >
          <img src={Devinfo} alt="devinfo" />
          <div style={{color: "#000", fontFamily: "Mulish",fontSize: "14px",fontStyle: "normal",fontWeight: "600",lineHeight: "normal"}}>Report a Problem</div>
        </div>
        <div className="devinfo">
          <img src={Devhub} alt="devhub" />
          <div style={{color: "#000", fontFamily: "Mulish",fontSize: "14px",fontStyle: "normal",fontWeight: "600",lineHeight: "normal"}}>Developer Hub</div>
        </div>
      </>
    );

    const profileContent = (
      <>
        <div className='d-flex gap-3 mb-2 align-items-center '>
          <div className='dialogLogo'>
            {user_name[0].toUpperCase()}
          </div>
          <div className='d-flex flex-column gap-1 dialogProfileName'>
          {user_name.split('@')[0]}
            <div className='dialogProfileNameP'>
              <p>View Profile</p>
            </div>
          </div>
        </div>
        <div className='dialogProfileEmail'>
          {user_name}
        </div>
        <hr
           style={{
            height: '1px',
            width: '100%',
            color: 'black',
            margin: '2px',
            padding: 0,
            }}
        />
        <div className='light-dark-switch d-flex'>
          <label>Light</label>
            <input
            type="checkbox"
            id="darkmode-toggle"
            checked={isDarkMode}
            onChange={toggleTheme}
            />
            <label htmlFor="darkmode-toggle" className="darkmode-label">
              <img src={Sun} alt="sun" className="sun" />
              <img src={Moon} alt="moon" className="moon" />
            </label>
          <label>Dark</label>
        </div>
        <hr
           style={{
            height: '1px',
            width: '100%',
            color: 'black',
            margin: 0,
            padding: 0,
            }}
        />
        <p className='logout_nav' onClick={() => logout()}>Logout</p>
      </>
    );
    const handleNavigation = async(e, path) => {
      if (isEditing) {
        e.preventDefault();
        const confirmLeave = window.confirm("You have unsaved changes, Are you sure you want to leave?");
        if (confirmLeave) {
          history(path);
          await dispatch(handleEditing(false));
        }
      }
    };
  return (
    <div className={`sidebar ${isPopoverOpen ? 'hovered' : ''}`}>
      {processorModule ? 
        (
          <div className='sidebar-icons'>
          <div className='sidebar-logo'>
            <img src={Intain} alt='Intain AI' className='logo-intain'/>
            <img src={IntainLogo} alt='Intain Logo' className='logo-intain-hidden'/>
          </div>
          <ul>
            <li className='issure-icons'>
              <Link to='/' title='Dashboard' className={
                        splitLocation[1] === "" 
                        ? "issure-icon-active"
                        : "issure-icon"} onClick={(e) => handleNavigation(e, '/')}>
                <img src={isDarkMode ? DashboardDark : Dashboard} alt='issuer'/>
                <span className='sidebar-text content-show'>
                  Dashboard
                </span>
              </Link>
            </li>
            <li className='issure-icons'>
              <Link to='/analytics' title='Analytics' onClick={() => {sessionStorage.getItem('activeAdmin') && sessionStorage.removeItem('activeAdmin')}} className={splitLocation[1] === "analytics" ? "issure-icon-active" : "issure-icon"}>
                <img src={isDarkMode ? Analytics : Analytics} alt='issuer'/>
                <span className='sidebar-text content-show'>
                  Analytics/IDA (Beta)
                </span>
              </Link>
            </li>
          </ul>
        </div>
        ):
        (
        <div className='sidebar-icons'>
          <div className='sidebar-logo'>
            <img src={Intain} alt='Intain AI' className='logo-intain'/>
            <img src={IntainLogo} alt='Intain Logo' className='logo-intain-hidden'/>
          </div>
          <ul>
            <li className='issure-icons'>
              <Link to='/' title='queue' onClick={() => {sessionStorage.getItem('activeAdmin') && sessionStorage.removeItem('activeAdmin')}} className={
                        splitLocation[1] === "" || 
                        splitLocation[1] === "queuemapprocessor" || splitLocation[1] === "datacheck"
                        ? "issure-icon-active"
                        : "issure-icon"}>
                <img src={isDarkMode ? QueueDark : Queue} alt='issuer'/>
                <span className='sidebar-text content-show'>
                  Queues
                </span>
              </Link>
            </li>
            <li className='issure-icons'>
              <Link to='/processor' title='processor' onClick={() => {sessionStorage.getItem('activeAdmin') && sessionStorage.removeItem('activeAdmin')}} className={splitLocation[1] === "processor" ? "issure-icon-active" : "issure-icon" }>
                <img src={isDarkMode ? ProcessorDark : Processor} alt='issuer'/>
                <span className='sidebar-text content-show'>
                  Processors
                </span>
              </Link>
            </li>
            <li className='issure-icons'>
              <Link to='/document' title='Document Types' onClick={() => {sessionStorage.getItem('activeAdmin') && sessionStorage.removeItem('activeAdmin')}} className={splitLocation[1] === "document" ? "issure-icon-active" : "issure-icon"}>
                <img src={isDarkMode ? DocumentDark : Document} alt='issuer'/>
                <span className='sidebar-text content-show'>
                  Document Types
                </span>
              </Link>
            </li>
            <li className='issure-icons'>
              <Link to='/field' title='Fields' onClick={() => {sessionStorage.getItem('activeAdmin') && sessionStorage.removeItem('activeAdmin')}} className={splitLocation[1] === "field" ? "issure-icon-active" : "issure-icon"}>
                <img src={isDarkMode ? FieldDark : Field} alt='issuer'/>
                <span className='sidebar-text content-show'>
                  Fields
                </span>
              </Link>
            </li>
            <li className='issure-icons'>
              <Link to='/adminupload' title='Upload' onClick={() => {sessionStorage.setItem('activeAdmin', 4); dispatch(setCurrentPageForInfinityScroll(1))}} className={splitLocation[1] === "adminupload" ? "issure-icon-active" : "issure-icon"}>
                <img src={isDarkMode ? UploadDark : Upload} alt='issuer'/>
                <span className='sidebar-text content-show'>
                  Uploads
                </span>
              </Link>
            </li>
            <li className='issure-icons'>
              <Link to='/analytics' title='Analytics' onClick={() => {sessionStorage.getItem('activeAdmin') && sessionStorage.removeItem('activeAdmin')}} className={splitLocation[1] === "analytics" ? "issure-icon-active" : "issure-icon"}>
                <img src={isDarkMode ? Analytics : Analytics} alt='issuer'/>
                <span className='sidebar-text content-show'>
                  Analytics
                </span>
              </Link>
            </li>
          </ul>
        </div>
        )}
        <div className='sidebar-footer'>
          <ul>
            <li className='sidebar-item'>
              <div className='sidebar-item-content'>
                <img src={isDarkMode ? InfoDark : Info} alt='Help'/>
                <span className="sidebar-text content-show">FAQ</span>
              </div>
            </li>
            <li className="sidebar-item">
            <Popover 
              placement="rightBottom" 
              content={profileContent} 
              trigger="click" 
              classNames={{wrapper: "custom-popover-profile"}}
              open={isPopoverOpen}
              onOpenChange={(visible) => {
                console.log({visible},'user info modal')
                setIsPopoverOpen(visible);
                if(visible){
                  handleShowUserInfoModal();
                } else {
                  handleCloseUserInfoModal();
                }
              }}>
              <div className='sidebar-item-content'>
                <div className='sidebar-item-profile'>
                  <p>{user_name[0].toUpperCase()}</p>
                </div>
                <div>
                <span className="sidebar-text content-show">
                  Profile & More
                </span>
                </div>
              </div>
            </Popover>
            </li>
          </ul>
        </div>
    </div>
  );
};

export default SideNavbar;
