import * as ActionType from './userActionTypes';

const initialQueuesState = {
  user: [],
  delete_status: [],
  review: [],
  pending: [],
  export_status: [],
  admin_upload_dashboard_search_term: '',
  contract_list: [],
  ltape_list: [],
  folders_list: [],
  zip_file: null,
  loan_tape: null,
  status: null,
  current_page: 1,
  analytics_dashboard_queue: [],
};

const userReducer = (state = initialQueuesState, { type, payload }) => {
  console.log(state.pending, 'checktype');

  switch (type) {
    case ActionType.REVIEW_DASHBOARD:
      return {
        ...state,
        review: payload,
      };
    case ActionType.PENDING_DASHBOARD:
      return {
        ...state,
        pending: payload,
      };
    case ActionType.EXPORT_STATUS:
      return {
        ...state,
        export_status: payload,
      };
    case ActionType.DELETE_STATUS:
      return {
        ...state,
        delete_status: payload,
      };
    case ActionType.ADMIN_UPLOAD_DASHBOARD:
      return {
        ...state,
        admin_upload_dashboard: payload,
      };
    case ActionType.IS_ADMIN:
      return {
        ...state,
        isAdmin: payload,
      };
      case ActionType.CONTRACT_LIST:
        return {
          ...state,
          contract_list: payload,
        };
        case ActionType.LTAPE_LIST:
          return {
            ...state,
            ltape_list: payload,
      };
    case ActionType.FOLDERS_LIST:
      return {
        ...state,
        folders_list: payload,
      };
    case ActionType.ZIP_FILE:
      return {
        ...state,
        zip_file: payload,
      };
    case ActionType.LOAN_TAPE:
      return {
        ...state,
        loan_tape: payload,
      };
    case ActionType.STATUS:
      return {
        ...state,
        status: payload,
      };
    case ActionType.CURRENT_PAGE:
      return {
        ...state,
        current_page: payload,
      };
    case ActionType.ANALYTICS_DASHBOARD_QUEUE:
      return {
        ...state,
        analytics_dashboard_queue: payload,
      };
    case 'TRACK_SERCH_TERM':
      return {
        ...state,
        admin_upload_dashboard_search_term: payload,
      };
    case 'CLEAR_SERCH_TERM':
      return {
        ...state,
        admin_upload_dashboard_search_term: payload,
      };
    default:
      return state;
  }
};

export default userReducer;
